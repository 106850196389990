import logo from './logo.svg';
import './App.css';
import vid from './img/mi.mp4'

function App() {
  return (
    <div className="App">
        <video className='vid' autoPlay muted width="100%" height="100%">
            <source src={vid} type='video/mp4' />
        </video>
    </div>
  );
}

export default App;
